<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

        <a-form-model-item label="平台用户" prop="userId" >
          <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
          <a-select
              v-model="form.userId"
              showSearch
              style="width: 100%"
              placeholder="请选择平台用户"
              allow-clear
              optionFilterProp="children"
          >
            <a-select-option :key="item.id"  v-for="(item,index) in listAll">
              {{item.nickname}}
            </a-select-option>
          </a-select>
        </a-form-model-item>


      <a-form-model-item label="佣金比例" prop="distributorRait" >
        <a-input-number :min="0" :precision="2" :max="1" v-model="form.distributorRait"  />
      </a-form-model-item>

      <a-form-model-item label="赠送钻石数" prop="giveDiamond" >
        <a-input-number :min="0" :precision="0" v-model="form.giveDiamond"  />
      </a-form-model-item>

      <a-form-model-item label="邮箱" prop="userEmail" >
        <a-input v-model="form.userEmail" placeholder="请输入邮箱" />
      </a-form-model-item>

      <a-form-model-item label="手机号" prop="userPhone" >
        <a-input v-model="form.userPhone" placeholder="请输入手机号" />
      </a-form-model-item>

      <a-form-model-item label="银行卡名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入银行名称" />
      </a-form-model-item>

      <a-form-model-item label="银行卡号" prop="bankNo" >
        <a-input v-model="form.bankNo" placeholder="请输入银行卡号" />
      </a-form-model-item>

      <a-form-model-item label="真实姓名" prop="realName" >
        <a-input v-model="form.realName" placeholder="请输入真实姓名" />
      </a-form-model-item>

      <a-form-model-item label="开户行地址" prop="bankAddress" >
        <a-input v-model="form.bankAddress" placeholder="请输入开户行地址" />
      </a-form-model-item>


      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDistributor, addDistributor, updateDistributor ,getUserLists} from '@/api/user/distributor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      listAll: [],
      // 表单参数
      form: {
        id: null,

        userId: null,

        inviteUrl: null,

        inviteCode: null,

        userEmail: null,

        userPhone: null,

        distributorRait: null,

        bankName: null,

        bankNo: null,

        realName: null,
        giveDiamond: null,

        bankAddress: null,

        status: 0,

        address: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '请选择用户', trigger: 'blur' }
        ],
        distributorRait: [
          { required: true, message: '请输入佣金比例', trigger: 'blur' }
        ],
        userEmail: [
          { required: true, message: '请输入用户邮箱', trigger: 'blur' },
          {
            pattern: /^1[3/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            message: '请正确填写邮箱',
            trigger: 'blur'
          }
        ],
        userPhone: [
          { required: true, message: '请输入用户电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写联系电话',
            trigger: 'blur'
          }

        ],
        bankName: [
          { required: true, message: '请输入银行卡名称', trigger: 'blur' }
        ],
        giveDiamond: [
          { required: true, message: '请输入赠送钻石数', trigger: 'blur' }
        ],

        bankNo: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' },
          {
            pattern: /^\d{16,19}$/,
            message: '请正确填写银行卡号',
            trigger: 'blur'
          }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        bankAddress: [
          { required: true, message: '请输入开户地址', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        giveDiamond: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: 0,
        address: null,
        createTime: null,
        remark: null,
      }
      this.getUserLists()
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    getUserLists() {
      getUserLists().then(res => {
        this.listAll = res.data;
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDistributor({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDistributor(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDistributor(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
